/**
 * Text
 */

import React from 'react';
import { graphql } from 'gatsby';
import { ContentParser } from '@components';

const Text = ({ content }) => (
  <ContentParser 
    className="page__block page__block--text"
    element="div"
    content={content}
  />
);

export default Text;

export const pageBuilderTextQuery = graphql`
  fragment PageBuilderText on WordPressAcf_text {
    ... on WordPressAcf_text {
      content
    }
  }
`